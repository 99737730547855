import React from 'react'
import classNames from 'classnames'
import { submitEmail } from '@helpers/contact-form'
import loaderLight from '@images/loader-light.svg'
import './contact-form.sass'

export default () => {
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(null)
  const clearForm = (success = true) => {
    setName('')
    setEmail('')
    setMessage('')
    setLoading(false)
    success
      ? setSuccess('Message sent!')
      : setSuccess('There was an error sending your message. Please try again.')
  }
  return (
    <form
      className="contact-form grid-x"
      data-testid="contact-form"
      onSubmit={e => {
        setSuccess(null)
        submitEmail(e, name, email, message, setLoading, clearForm)
      }}
    >
      <label className="cell small-12 medium-6">
        Name
        <br />
        <input
          type="text"
          name="name"
          data-testid="name"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </label>
      <br />
      <label className="cell small-12 medium-6">
        Email
        <br />
        <input
          type="email"
          name="email"
          data-testid="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </label>
      <br />
      <label className="small-12">
        Message
        <br />
        <textarea
          type="text"
          name="message"
          data-testid="message"
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
      </label>
      <br />
      <button type="submit">{loading ? <img src={loaderLight} alt="loading" /> : 'Submit'}</button>
      {success && (
        <span
          className={classNames({
            success: success && success.includes('sent!'),
            failure: success && success.includes('try again'),
          })}
        >
          {success}
        </span>
      )}
    </form>
  )
}

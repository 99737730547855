import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '@components/layout'
import Hero from '@components/hero'
import ContactForm from '@components/contact-form'

export default ({ data, location }) => {
  return (
    <Layout className="contact" location={location}>
      <Helmet title={`Contact | ${data.contentfulPerson.name}`} />
      <Hero
        data={{
          name: 'Contact Me',
          shortBio:
            'Please reach out if you would like to chat. Whether simply to get to know me, or to discuss a professional venture.',
        }}
      />
      <div className="wrapper">
        <ContactForm />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactQuery {
    contentfulPerson(contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" }) {
      name
    }
  }
`

import axios from 'axios'

export const submitEmail = (e, name, email, message, setLoading, clearForm) => {
  e.preventDefault()
  setLoading(true)
  const today = new Date()
  const time = `${today.getFullYear()}-${today.getMonth() +
    1}-${today.getDate()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
  const data = { name, email, message, time }

  axios
    .post(`${process.env.GATSBY_TD_SERVICE_URL}/submit`, data)
    .then(() => clearForm(true))
    .catch(() => clearForm(false))
}
